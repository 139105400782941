import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: "https://56682c33e35f4d20bd7c03474cfb9827@o4504163489087488.ingest.sentry.io/4504163498196992",
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ["localhost", "https://id.carrot.net"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Integrations.Breadcrumbs({
      console: false
    })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

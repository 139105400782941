export const environment = {
  firebase: {
    apiKey: "AIzaSyDqS6MFDisYkLBfbBLNaE8ZGKkBjZwiLR8",
    authDomain: "prod-carrot-sso.firebaseapp.com",
    projectId: "prod-carrot-sso",
    storageBucket: "prod-carrot-sso.appspot.com",
    messagingSenderId: "1082804228699",
    appId: "1:1082804228699:web:5f4e1246b0d5432f6c657e",
    measurementId: "G-WJF7KWMHTF"
  },
  production: true,
  name: "PROD",
  useEmulators: false,
  apiBaseUrl: "",
  clientId: "cKtwEO58pTW6ZzuvzIiU"
};
